import { Link } from "react-router-dom";
import { useState } from "react";
import { FiAlignLeft, FiLogOut, FiSliders, FiUser, FiUsers, FiCalendar, FiDollarSign, FiBook, FiMenu, FiFileText, FiImage, FiFile, FiShare2, FiFilter, FiThermometer, FiCpu } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import useAuth from '../Auth/hook_useAuth';

function Sidebar() {
    const navigate = useNavigate();
    const { signout } = useAuth();

    const closeSidebar = () => {
        setIsSidebarVisible(false);
    };

    const handleLogoff = (e) => {
        e.preventDefault();
        signout();
        navigate('/login');
    };

    // Controla o sidedar
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    return (
        <>
            <Link className={`sidebar-toggle js-sidebar-toggle ${isSidebarVisible ? 'btn-sidebar-collapsed' : 'btn-sidebar'}`} onClick={toggleSidebar}>
                <FiAlignLeft className="align-self-center fs-1 text-hamburger" />
            </Link>
            <div className={`sidebar-placeholder ${isSidebarVisible ? 'sidebar-placeholder-collapsed' : ''}`}></div>
            <nav id="sidebar" className={`sidebar ${isSidebarVisible ? 'collapsed' : ''}`}>
                <div className="sidebar-content">
                    <Link className="sidebar-brand">
                        <span className="align-middle">Trackster</span>
                    </Link>
                    <ul className="sidebar-nav">
                        <li className="sidebar-header">
                            Painel de Controle
                        </li>

                        <li className="sidebar-item">
                            <Link className="sidebar-link" onClick={closeSidebar} to="/dashboard">
                                <FiSliders /> <span className="align-middle">Dashboard</span>
                            </Link>
                        </li>

                        <li className="sidebar-header">
                            Data
                        </li>

                        <li className="sidebar-item">
                            <Link className="sidebar-link" onClick={closeSidebar} to="/filtrar-dados">
                                <FiFilter /> <span className="align-middle">Filter</span>
                            </Link>
                        </li>

                        <li className="sidebar-item">
                            <Link className="sidebar-link" onClick={closeSidebar} to="/heatmaps">
                                <FiThermometer /> <span className="align-middle">Heatmaps</span>
                            </Link>
                        </li>

                        <li className="sidebar-header">
                            Em breve
                        </li>

                        <li className="sidebar-item">
                            <Link className="sidebar-link" onClick={closeSidebar} to="/dashboard">
                                <FiCpu /> <span className="align-middle">AI</span>
                            </Link>
                        </li>



                        <li className="sidebar-header">
                            Outros
                        </li>

                        <li className="sidebar-item">
                            <Link className="sidebar-link" onClick={closeSidebar} to="/perfil">
                                <FiUser /> <span className="align-middle">Meu Perfil</span>
                            </Link>
                        </li>


                    </ul>


                    <Link className="sidebar-link" onClick={handleLogoff} to="#">
                        <FiLogOut /> <span className="align-middle">Logoff</span>
                    </Link>

                </div>
            </nav>
        </>
    )
}

export default Sidebar;
